
import { Component, Vue } from 'vue-property-decorator'
import { Page } from '../../types/common'
import { PageType } from '../../types/trainingVideo'
import { FileInfo } from '@/types/common'

@Component({
  name: 'TrainingVideo',
  filters: {
    isShowFilters (value: number) {
      return value === 0 ? '否' : value === 1 ? '是' : ''
    }
  }
})
export default class extends Vue {
  private dialogTableVisible = false
  private searchInfo = {
    videoName: '',
    isShow: '',
    tagName: ''
  }

  private tableData: PageType[] = []
  private videoUrl = ''
  private loading = false
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.trainingVideo.videoPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData = res.list || []
        this.total = res.total || 0
      })
      .finally(() => {
        this.loading = false
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onPlay (videoFile: FileInfo) {
    if (videoFile && videoFile.filePrefix) {
      this.dialogTableVisible = true
      this.videoUrl = videoFile.filePrefix + videoFile.fileUrl
    }
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'trainingVideoUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.trainingVideo.videoDelete, {
          videoId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
          .catch((err) => {
            this.$message.error(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onAdd () {
    this.$router.push({ name: 'trainingVideoAdd' })
  }

  close () {
    this.videoUrl = '';
    (this.$refs.video as any).pause() // 暂停视频播放
  }
}
